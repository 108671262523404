import React from 'react'

import bpm from '../images/solutions/bpm.png'
import compliance from '../images/solutions/compliance.png'
import controladoria from '../images/solutions/controladoria.png'
import okr from '../images/solutions/okr.png'
import rpa from '../images/solutions/rpa.png'
import sgi from '../images/solutions/sgi.png'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const solutionsItems = [
  {
    title: 'BPM',
    subtitle: `Gestāo por Processos de Negócio`,
    description: `Através do mapeamento dos processos iremos sugerir melhorias baseadas em boas práticas de mercado para padronizar e melhorar os processos de acordo com o planejamento estratégico da organização.`,
    image: <img src={bpm} alt="BPM" />,
    link: (
      <>
        <AnchorLink to={`/gestao-por-processos-de-negocio-bpm`}>
          Saiba mais
        </AnchorLink>
      </>
    ),
  },
  {
    title: 'RPA',
    subtitle: `Automaçāo Robótica de Processos`,
    description: `Conheça a nossa ferramenta para Automação Robótica de Processos: o Roberty Automation. Automatize tarefas operacionais, manuais e repetitivas. Invista o capital intelectual humano em tarefas de maior raciocínio e criatividade.`,
    image: <img src={rpa} alt="RPA" />,
    link: (
      <>
        <AnchorLink to={`/automacao-robotica-de-processos`}>
          Saiba mais
        </AnchorLink>
      </>
    ),
  },
  {
    title: 'Controladoria',
    subtitle: `Estruturaçāo do Processo`,
    description: `Alavanque os seus resultados. Atuamos na Formação do Preço de Venda, Apuração de Custos, Implantação de Controladoria, Planejamento e Controle Orçamentário, Consultoria Financeira, Análise de Viabilidade, dentre outros.`,
    image: <img src={controladoria} alt="Controladoria" />,
    link: (
      <>
        <AnchorLink to={`/controladoria`}>Saiba mais</AnchorLink>
      </>
    ),
  },
  {
    title: 'SGI',
    subtitle: `Sistema de Gestão Integrado`,
    description: `Atuamos desde a implantação do Sistema de Gestão até a preparação para as certificações reconhecidas nacional e internacionalmente: ISO 9001, ISO 14001, ISO 45001, ISO 27001, ISO 55001, dentre outras.`,
    image: <img src={sgi} alt="SGI" />,
    link: (
      <>
        <AnchorLink to={`/sistema-de-gestao-integrado`}>Saiba mais</AnchorLink>
      </>
    ),
  },
  {
    title: 'OKR',
    subtitle: `Implementaçāo de Gestāo Ágil`,
    description: `Conheça nossa metodologia para definição de objetivos estratégicos ligados aos resultados-chave. Implantamos a gestão à vista para que todos saibam onde tem que chegar.`,
    image: <img src={okr} alt="OKR" />,
    link: (
      <>
        <AnchorLink to={`/okr-objetivos-e-resultados-chaves`}>
          Saiba mais
        </AnchorLink>
      </>
    ),
  },
  {
    title: 'Compliance',
    subtitle: `Implementaçāo de Boas Práticas`,
    description: `Fazemos um diagnóstico para entender como está a integridade e a gestão de riscos da organização e de acordo com a prioridade atacamos as falhas através de políticas e controles internos.`,
    image: <img src={compliance} alt="Compliance" />,
    link: (
      <>
        <AnchorLink to={`/compliance`}>Saiba mais</AnchorLink>
      </>
    ),
  },
]
